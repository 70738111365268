import wz from './wzJsgraphics';

export default function Dendrogram(nodes) {
  const ROW_HEIGHT = 74;
  const graphics = new wz.jsGraphics('dendrogram-content');

  /**
   * Draw dendrogram
   */
  this.paint = function () {
    const clusterPosition = {};
    const nodeDistances = [];
    nodes.forEach((node) => {
      const cloneNode = {
        xc1: 0,
        xc2: 0,
        yc1: null,
        yc2: null,
        xcn: node.distance,
        color: this.getColor(node.distance),
        ...node,
      };
      const position = { left: null, right: null };
      this.leftNodeData(node, cloneNode, position);
      this.rightNodeData(node, cloneNode, position);
      clusterPosition[node.name] = position;
      nodeDistances.push(cloneNode);
    });

    nodeDistances.reverse().forEach((nd) => {
      const position = { ...nd };

      if (!nd.yc1 && !nd.yc2) {
        position.yc1 = (clusterPosition[nd.right].left + clusterPosition[nd.right].right) / 2;
        clusterPosition[nd.name].right = position.yc1;
        position.yc2 = (clusterPosition[nd.left].left + clusterPosition[nd.left].right) / 2;
        clusterPosition[nd.name].left = position.yc2;
      } else if (!nd.yc1) {
        if (!clusterPosition[nd.right]) return;

        position.yc1 = (clusterPosition[nd.right].left + clusterPosition[nd.right].right) / 2;
        clusterPosition[nd.name].right = position.yc1;
      } else if (!nd.yc2) {
        if (!clusterPosition[nd.left]) return;

        position.yc2 = (clusterPosition[nd.left].left + clusterPosition[nd.left].right) / 2;
        clusterPosition[nd.name].left = position.yc2;
      }

      this.drawDendrogram(position.xc1, position.yc1, position.xc2, position.yc2, position.xcn, position.color);
    });
    graphics.paint();
  };

  this.leftNodeData = function(node, cloneNode, position) {
    const lNode = nodes.find((x) => x.name === node.left);
    if (lNode) {
      cloneNode.xc2 = lNode.distance;
      const employeeDom = document.getElementById(node.left);
      if (employeeDom) {
        cloneNode.yc2 = +employeeDom.dataset.index;
        position.left = cloneNode.yc2;
      }
    } else {
      cloneNode.xc2 = 1.08;
      const employeeDom = document.getElementById(node.left);
      if (employeeDom) {
        cloneNode.yc2 = +employeeDom.dataset.index;
        position.left = cloneNode.yc2;
      }
    }
  }

  this.rightNodeData = function(node, cloneNode, position) {
    const rNode = nodes.find((x) => x.name === node.right);
    if (rNode) {
      cloneNode.xc1 = rNode.distance;
      const employeeDom = document.getElementById(node.right);
      if (employeeDom) {
        cloneNode.yc1 = +employeeDom.dataset.index;
        position.right = cloneNode.yc1;
      }
    } else {
      cloneNode.xc1 = 1.08;
      const employeeDom = document.getElementById(node.right);
      if (employeeDom) {
        cloneNode.yc1 = +employeeDom.dataset.index;
        position.right = cloneNode.yc1;
      }
    }
  }

  this.getColor = function (value) {
    if (value <= 1 && value >= 0.6) return 1;
    if (value < 0.6 && value >= 0) return 2;

    return 3;
  };

  /**
   *
   * @param xc1    horizontal line 1
   * @param yc1    vertical line 1
   * @param xc2    horizontal line 2
   * @param yc1    vertical line 2
   * @param xcn    value
   * @param color  (1: blue, 2: yellow, 3：red）
   */
  this.drawDendrogram = function (xc1, yc1, xc2, yc2, xcn, color) {
    var dspVal = xcn;
    var ch;
    var chs;

    switch (color) {
      case 1:
        ch = '#5e84fd';
        chs = '#435eb5';
        break;
      case 2:
        ch = '#ffe51f';
        chs = '#c6b42a';
        break;
      case 3:
        ch = '#ff0000';
        chs = '#b31212';
        break;
      default:
        ch = '#ffffff';
        chs = '#cccccc';
        break;
    }

    xc1 = 36 + 150 * (xc1 + 1.0);
    xc2 = 36 + 150 * (xc2 + 1.0);
    xcn = 36 + 150 * (xcn + 1.0);
    yc1 = 30 + (yc1 - 1) * ROW_HEIGHT;
    yc2 = 30 + (yc2 - 1) * ROW_HEIGHT;

    this.drawLine(graphics, xc1 + 2, yc1 + 2, xc2 + 2, yc2 + 2, xcn + 2, chs, 3);
    this.drawLine(graphics, xc1, yc1, xc2, yc2, xcn, ch, 3);
    this.drawString(graphics, '#808080', dspVal, xcn - 15 + 1, yc1 - 15 + 1);
    this.drawString(graphics, ch, dspVal, xcn - 15, yc1 - 15);
  };

  this.drawLine = function (graphics, xc1, yc1, xc2, yc2, xcn, rgbValue, stroke) {
    graphics.setColor(rgbValue);
    graphics.setStroke(stroke);
    graphics.drawLine(xc1, yc1, xcn, yc1); // Draw a branch of A cluster
    graphics.drawLine(xc2, yc2, xcn, yc2); // Draw a branch of B cluster
    graphics.drawLine(xcn, yc1, xcn, yc2); // Draw a vertical branch of the cluster
  };

  this.drawString = function (graphics, rgbValue, string, x, y) {
    graphics.setColor(rgbValue);
    graphics.setFont('sans-serif', '12px', 'font-weight:bold;');
    graphics.drawString(string, x, y);
  };
}
